.home-training-float-icon-box {
  position: fixed;
  bottom: 32px;
  right: 24px;
  z-index: 100;
}
.home-training-float-icon-box .home-training-float-icon {
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
          border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 72px;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item {
  padding: 15px 0;
  text-align: center;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item:last-child {
  border-bottom: none;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item .float-icon-item-img {
  width: 24px;
  height: 24px;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item .float-icon-item-text {
  margin-top: 4px;
  font-size: 12px;
  color: #333333;
  line-height: 20px;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item:hover .float-icon-item-qrcode {
  display: block;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item .float-icon-item-qrcode {
  position: absolute;
  top: 4px;
  height: 152px;
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
          border-radius: 8px;
  padding: 15px 0 8px;
  width: 135px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  display: none;
  left: -164px;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item .float-icon-item-qrcode::after {
  content: '';
  width: 11px;
  height: 11px;
  background: #ffffff;
  position: absolute;
  right: -5px;
  top: 38px;
  -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
          transform: rotate(45deg);
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item .float-icon-item-qrcode .float-icon-item-qrcode-img {
  width: 104px;
  height: 104px;
}
.home-training-float-icon-box .home-training-float-icon .float-icon-item .float-icon-item-qrcode .float-icon-item-qrcode-text {
  margin-top: 8px;
  font-size: 12px;
  color: #333333;
  line-height: 17px;
}
.home-training-float-icon-box .scroll-top-button {
  margin-top: 16px;
  width: 72px;
  height: 80px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 8px;
          border-radius: 8px;
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.home-training-float-icon-box .scroll-top-button .float-icon-item-img {
  width: 24px;
  height: 24px;
}
.home-training-float-icon-box .scroll-top-button .float-icon-item-text {
  margin-top: 4px;
  font-size: 12px;
  color: #333333;
  line-height: 20px;
}
